<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <v-card elevation="0">
          <v-card-text>
            <p>Eigenentwicklung Angaben</p>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-select
                  v-model="programmiersprachen"
                  :items="allprogrammiersprachen"
                  item-text="bezeichnung"
                  item-value="id"
                  label="verwendete Programmiersprachen"
                  multiple
                  return-object
                  dense
                >
                  <template v-slot:selection="data">                    
                    {{ data.item.bezeichnung }} - {{ data.item.version }},
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.bezeichnung }} - {{ data.item.version }}
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedProgrammiersprachen.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      selectedProgrammiersprachen: [],
      rules: {}
    };
  },
  computed: {
    programmiersprachen: {
      get() {
        return this.$store.getters.currentSystem.programmierSprachen;
      },
      set(value) {
        this.$store.commit("updateProgrammierSprachen", value);
      }
    },
    allprogrammiersprachen() {
      return this.$store.getters.programmiersprachen;
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        this.selectedProgrammiersprachen = this.allprogrammiersprachen.slice();
      });
    }
  }
};
</script>

<style></style>
