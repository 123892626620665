<template>
  <v-footer default color="transparent">
    <v-row justify="end">
      <v-dialog v-model="helpDialog" width="600px" scrollable>
        <template v-slot:activator="{ on, attrsDialog }">
          <v-btn
            class="ma-2"
            text
            icon
            color="secondary"
            v-bind="attrsDialog"
            v-on="on"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5 text-decoration-underline">Übersicht</span>
          </v-card-title>
           <v-card-text
            class="text-center"
            style="height: 400px; text-align: start !important;"
          >
            Die <strong>Übersicht</strong> bietet die Möglichkeit grundlegende
            Informationen zu dem System zu definieren.
            <br />
            Neben einige Stamminformationen kann hier auch definiert werden, ob
            das System eine
            <ul>
              <li>Eigenentwicklung</li>
              <li>Kommenzielles Produkt</li>
              <li>oder ein Open Source (OSS) Produkt</li>
            </ul>
            ist.
            <br />
          </v-card-text>
          
          <v-card-actions>
            <v-btn text color="secondary" @click="reveal = true">
              Weitere Hilfe
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      helpDialog: false,
      reveal: false
    };
  }
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
