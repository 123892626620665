<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <v-card elevation="0">
          <v-card-text>
            <p>Open Source Angaben</p>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="osslizenzen"
                  :items="allosslizenzen"
                  item-text="bezeichnung"
                  item-value="id"
                  label="Lizenz"
                  multiple
                  return-object
                  dense
                >
                  <template v-slot:prepend-item>
                    <v-list-item @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedOsslizenzen.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  label="Url"
                  v-model="ossUrl"
                  :rules="rules.urlRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,      
      selectedOsslizenzen: [],
      rules: {
        urlRules: [
          v => !!v || "URL des Produktes (bzw. Download Page) erforderlich"
        ]
      }
    };
  },
  computed: {
    ossUrl:{
      get(){
        return this.$store.getters.currentSystem.ossUrl;
      },
      set(value){
        this.$store.commit("updateOssUrl",value);
      }
    },
    osslizenzen: {
      get() {
        return this.$store.getters.currentSystem.ossLizenzen;
      },
      set(value) {
        this.$store.commit("updateOssLizenzen", value);
      }
    },
    allosslizenzen() {
      return this.$store.getters.lizenzen;
    }
  },
  async created() {
    this.$store.dispatch("getOssLizenzen");
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        this.selectedOsslizenzen = this.allosslizenzen.slice();
      });
    }
  }
};
</script>

<style></style>
