<template>
  <div>
    <v-toolbar flat>
      <help />
      <v-toolbar-title>Übersicht {{ bezeichnung }}</v-toolbar-title>
    </v-toolbar>
    <v-form ref="form" v-model="valid" >
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              dense
              label="Bezeichnung"
              v-model="bezeichnung"
              :rules="rules.bezeichnungRules" @change="markAsUnsaved"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-select
              :items="architekturen"
              v-model="architekturTyp"
              item-text="name"
              item-value="id"
              label="Architektur Typ"
              required
              dense
              @change="markAsUnsaved"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-select
              :items="systemTypen"
              v-model="systemTyp"
              item-text="bezeichnung"
              item-value="id"
              label="System Typ"
              required
              dense
              @change="markAsUnsaved"
              :rules="rules.systemTypRules"
            ></v-select>
          </v-col>
        </v-row>

        <div v-if="systemTyp.id === 0">
          <eigenentwicklung-information />
        </div>
        <div v-else-if="systemTyp.id === 1">
          <cots-information />
        </div>
        <div v-else-if="systemTyp.id === 2">
          <opensourceInformation />
        </div>

        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              dense
              label="Architekt"
              v-model="architekt"
              :rules="rules.architektRules"
              @change="markAsUnsaved"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              dense
              label="Fachabteilung"
              v-model="fachAbteilung"
              :rules="rules.fachAbteilungRules"
              @change="markAsUnsaved"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="12">
            <v-select
              v-model="fachFunktionen"
              :items="fachlichefunktionen"
              item-text="bezeichnung"
              item-value="id"
              label="Unternehmens Funktions- bzw- Prozessmodell"
              multiple
              return-object
              :rules="rules.fachFunktionenRules"
              @change="markAsUnsaved"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="toggle">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        selectedFunktionen.length > 0 ? 'indigo darken-4' : ''
                      "
                    >
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="12">
            <v-textarea
              label="Beschreibung"
              required
              auto-grow
              rows="1"
              v-model="beschreibung"
              :rules="rules.beschreibungRules"
              @change="markAsUnsaved"
            ></v-textarea>
          </v-col>
          <v-col sm="2">
            <v-btn class="mr-1" @click="saveSystem" outlined>
              speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SystemService from "@/services/old/SystemService.js";
import CotsInformation from "../../../components/old/CotsInformation.vue";
import EigenentwicklungInformation from "../../../components/old/EigenentwicklungInformation.vue";
import OpensourceInformation from "../../../components/old/OpenSourceInformation.vue";
import Help from "@/components/old/help/UebersichtHelp.vue";

const sysTypes = [
  { id: 0, bezeichnung: "Eigenentwicklung" },
  { id: 1, bezeichnung: "COTS" },
  { id: 2, bezeichnung: "Open Source" }
];

const arcTypes = [
  { id: 1, name: "2-Schichten" },
  { id: 2, name: "3-Schichten" },
  { id: 3, name: "Batch" }
];

export default {
  name: "Uebersicht",
  components: {
    CotsInformation,
    EigenentwicklungInformation,
    OpensourceInformation,
    Help
  },
  data() {
    return {
      valid: true,
      rules: {
        bezeichnungRules: [v => !!v || "Bezeichnung erforderlich"],
        systemTypRules: [v => !!v || "Angabe des System Typs erforderlich"],
        architektRules: [
          v => !!v || "Angabe des verantwortlichen Architekten erforderlich"
        ],
        fachAbteilungRules: [
          v => !!v || "Angabe der verantwortlichen Fachabteilung erforderlich"
        ],
        fachFunktionenRules: [
          v =>
            !!v ||
            "Angabe der referenzierten Unternehmensfunktionen erforderlich",
          v =>
            v.length > 0 ||
            "Angabe der referenzierten Unternehmensfunktionen erforderlich"
        ],
        beschreibungRules: [
          v => !!v || "Angabe einer Beschreibung erforderlich"
        ]
      },
      architekturen: arcTypes,
      systemTypen: sysTypes,
      selectedFunktionen: [],
      snackbar: false,
      message: "",
      snackbar_timeout: 2000,
      form_saved: true
    };
  },
  computed: {
    architekt: {
      get() {
        return this.$store.getters.currentSystem.architekt;
      },
      set(value) {
        this.$store.commit("updateArchitect", value);
      }
    },
    bezeichnung: {
      get() {
        return this.$store.getters.currentSystem.bezeichnung;
      },
      set(value) {
        this.$store.commit("updateBezeichnung", value);
      }
    },
    systemTyp: {
      get() {
        let currValue = this.$store.getters.currentSystem.systemTyp;
        if (currValue != null) {
          return sysTypes.find(function(item) {
            return item.id == currValue;
          });
        } else {
          return false;
        }
      },
      set(value) {
        this.$store.commit("updateSystemtyp", value);
      }
    },
    architekturTyp: {
      get() {
        let currValue = this.$store.getters.currentSystem.architekturTyp;
        if (currValue != null) {
          return arcTypes.find(function(item) {
            return item.id == currValue;
          });
        } else {
          return false;
        }
      },
      set(value) {
        this.$store.commit("updateArchitekturtyp", value);
      }
    },
    fachAbteilung: {
      get() {
        return this.$store.getters.currentSystem.fachAbteilung;
      },
      set(value) {
        this.$store.commit("updateFachAbteilung", value);
      }
    },
    fachFunktionen: {
      get() {
        return this.$store.getters.currentSystem.unterstuetzeFunktionen;
      },
      set(value) {
        this.$store.commit("updateUnterstuetzeFunktionen", value);
      }
    },
    beschreibung: {
      get() {
        return this.$store.getters.currentSystem.beschreibung;
      },
      set(value) {
        this.$store.commit("updateBeschreibung", value);
      }
    },
    fachlichefunktionen() {
      return this.$store.getters.fachfunktionen;
    }
  },
  async created() {
    this.$store.dispatch("getFachFunktionen");
    this.$store.dispatch("getProduktgruppen");
  },
  mounted() {},
  methods: {
    toggle() {
      this.$nextTick(() => {
        this.selectedFunktionen = this.fachlicheFunktionen.slice();
      });
    },
    markAsUnsaved(){
      this.form_saved=false;
    },
    async saveSystem() {
      this.validate();
      if (this.valid) {
        this.$store.dispatch("resetSystemTyp");

        let resp = await this.$store.dispatch("updateCurrentSystem");
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.form_saved = true;
        } else {
          this.message = resp.data;
        }
        this.refreshCurrentSystem();
        this.snackbar = true;
      }
    },
    async refreshCurrentSystem() {
      let resp1 = await SystemService.getNewestVersionOfSystem(
        this.$store.getters.currentSystemId
      );
      var currSystem = resp1.data;
      this.$store.dispatch("registerSystem", currSystem);

      if (resp1.status == 200) {
        this.message = "Änderungen wurden gespeichert.";
        this.form_saved = true;
      } else {
        this.message = resp1.errorMessage;
      }
    },
    architekturTypeText(row) {
      return this.architekturen.find(function(item) {
        return item.id === parseInt(row.architekturTyp);
      }).name;
    },
    validate() {
      this.$refs.form.validate();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.form_saved) {
      const answer = window.confirm(
        "Änderungen sind noch nicht gespeichert worden.\nSollen die Änderungen verworfen werden?"
      );
      if (answer) {
        this.form_saved = true;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
