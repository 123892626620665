<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <v-card elevation="0">
          <v-card-text>
            <p>COTS Angaben</p>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  label="Hersteller"
                  v-model="hersteller"
                  :rules="rules.cots_herstellerRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  label="Lizenz"
                  v-model="lizenzModel"
                  :rules="rules.cots_lizenzRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-checkbox
                  v-model="supportVorhanden"
                  label="Support verfügbar: "
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,

      rules: {
        cots_herstellerRules: [
          v => !!v || "Angabe eines Herstellers erforderlich"
        ],
        cots_lizenzRules: [
          v => !!v || "Angabe eines Lizenzmodells erforderlich"
        ]
      }
    };
  },
  computed: {
    hersteller: {
      get() {        
        return this.$store.getters.currentSystem.cots.hersteller;
      },
      set(value) {
        this.$store.commit("updateCotsHersteller", value);
      }
    },
    lizenzModel: {
      get() {
        return this.$store.getters.currentSystem.cots.lizenzModel;
      },
      set(value) {
        this.$store.commit("updateCotsLizenzModel", value);
      }
    },
    supportVorhanden: {
      get() {
        return this.$store.getters.currentSystem.cots.supportVorhanden;
      },
      set(value) {
        this.$store.commit("updateCotsSupportVorhanden", value);
      }
    }
  }
};
</script>

<style></style>
